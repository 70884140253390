import { useEffect, useRef, useState } from "react";
import { FormError } from "../FormInput";
import cities from "../../data/city.json";
import districts from "../../data/districts.json";

function CustomFormSelect({
  setFieldValue,
  errorMessageCity,
  errorMessageDistrict,
  isCityInvalid,
  isDistrictInvalid,
  cityValue,
  districtValue,
}: {
  setFieldValue: any;
  errorMessageCity: string | undefined;
  errorMessageDistrict: string | undefined;
  isCityInvalid: boolean;
  isDistrictInvalid: boolean;
  cityValue: string;
  districtValue: string;
}) {
  const [cityId, setCityId] = useState<string>("");

  return (
    <>
      <CitySelect
        setCityId={setCityId}
        cityValue={cityValue}
        setFieldValue={setFieldValue}
        errorMessageCity={errorMessageCity}
        isCityInvalid={isCityInvalid}
      />
      <DistrictSelect
        cityId={cityId}
        districtValue={districtValue}
        districts={districts}
        setFieldValue={setFieldValue}
        errorMessageDistrict={errorMessageDistrict}
        isDistrictInvalid={isDistrictInvalid}
        cityValue={cityValue}
      />
    </>
  );
}

export default CustomFormSelect;

const CitySelect = ({
  setFieldValue,
  cityValue,
  errorMessageCity,
  isCityInvalid,
  setCityId,
}: {
  setFieldValue: any;
  cityValue: string;
  errorMessageCity: string | undefined;
  isCityInvalid: boolean;
  setCityId: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };
  return (
    <>
      <div
        className={`flex w-full z-20 relative h-[50px] lg:h-[50px] rounded-full pl-4 lg:pl-[20px] text-black border border-[#E3E3E3] z-2 cursor-pointer pr-4 ${
          cityValue ? "bg-[#e8f0fe]" : "bg-white"
        }`}
        onClick={toggleMenu}
      >
        {isMenuOpen && (
          <div className="flex flex-col w-full absolute left-0 top-[49px] max-h-[300px] overflow-auto rounded border border-[#E3E3E3] bg-white p-4">
            {cities
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((city, idx) => (
                <div
                  className="w-full border-b border-[#E3E3E3] py-1 "
                  onClick={(e) => {
                    setFieldValue("city", city.name);
                    setCityId(city.id);
                  }}
                  key={idx}
                >
                  <p>{city.name}</p>
                </div>
              ))}
          </div>
        )}
        <div className="flex text-[24px] lg:text-[28px]  justify-between w-full items-center">
          {cityValue ? (
            <p>{cityValue}</p>
          ) : (
            <p className="text-[#9ca3af]">İl</p>
          )}
          <img
            className="w-6 select-none"
            src="/images/customSelectIcon.png"
            alt=""
          />
        </div>
      </div>
      {errorMessageCity && isCityInvalid && (
        <FormError error={errorMessageCity} />
      )}
    </>
  );
};

const DistrictSelect = ({
  districts,
  setFieldValue,
  districtValue,
  errorMessageDistrict,
  isDistrictInvalid,
  cityValue,
  cityId,
}: {
  districts: CityDistricts[] | undefined;
  setFieldValue: any;
  districtValue: string;
  errorMessageDistrict: string | undefined;
  isDistrictInvalid: boolean;
  cityValue: string;
  cityId: string;
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };
  return (
    <>
      <div
        className={`flex w-full z-10 relative h-[50px] lg:h-[50px] rounded-full pl-4 lg:pl-[20px] text-black border border-[#E3E3E3] z-2 cursor-pointer pr-4 ${
          districtValue ? "bg-[#e8f0fe]" : "bg-white"
        }`}
        onClick={toggleMenu}
      >
        {isMenuOpen && (
          <>
            {cityValue ? (
              <div className="flex flex-col w-full absolute left-0 top-[49px] max-h-[300px] overflow-auto rounded border border-[#E3E3E3] bg-white p-4">
                {districts
                  ?.filter((item) => item.il_id === cityId)
                  .map((district, idx) => (
                    <div
                      className="w-full border-b border-[#E3E3E3] py-1 "
                      onClick={(e) => {
                        setFieldValue("district", district.name);
                      }}
                      key={idx}
                    >
                      <p>{district.name}</p>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="flex flex-col w-full absolute left-0 top-[49px] max-h-[300px] overflow-auto rounded border border-[#E3E3E3] bg-white p-4">
                <p className="text-[#9ca3af]">Lütfen bir il seçiniz.</p>
              </div>
            )}
          </>
        )}

        <div className="flex text-[24px] lg:text-[28px]  justify-between w-full items-center">
          {districtValue ? (
            <p>{districtValue}</p>
          ) : (
            <p className="text-[#9ca3af]">İlçe</p>
          )}

          <img
            className="w-6 select-none"
            src="/images/customSelectIcon.png"
            alt=""
          />
        </div>
      </div>
      {errorMessageDistrict && isDistrictInvalid && (
        <FormError error={errorMessageDistrict} />
      )}
    </>
  );
};

type CityDistricts = {
  id: string;
  il_id: string;
  name: string;
};
