export function getCodeFromUrl(): string | null {
  if (typeof window !== "undefined") {
    const url = window.location.href;
    if (url.includes("c=")) {
      const codeIndex = url.indexOf("c=") + 2;
      const code = url.substring(codeIndex, codeIndex + 10);
      return code;
    }
  }

  return null;
}
