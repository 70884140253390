import { Field } from "formik";

type FormInputProps = {
  name: string;
  placeholder: string;
  type?: string;
  errorMessage?: string | undefined;
  isInvalid?: boolean | "";
};

function FormInput({
  name,
  placeholder,
  type = "text",
  isInvalid,
  errorMessage,
}: FormInputProps) {
  return (
    <>
      {name === "address" ? (
        <>
          <Field
            as="textarea"
            name={name}
            placeholder={placeholder}
            type="text"
            className="w-full h-[120px] lg:h-[150px] rounded-3xl lg:rounded-3xl pl-4 lg:pl-[20px] text-[24px] lg:text-[28px] text-black pt-2 lg:pt-2 resize-none"
          />
          {isInvalid && <FormError error={errorMessage} />}
        </>
      ) : (
        <>
          <Field
            name={name}
            placeholder={placeholder}
            type={type}
            className="w-full h-[50px] lg:h-[50px] rounded-full pl-4 lg:pl-[20px] text-[24px] lg:text-[28px] text-black"
          />
          {isInvalid && <FormError error={errorMessage} />}
        </>
      )}
    </>
  );
}

export default FormInput;

export const FormError = ({ error }: { error: string | undefined }) => {
  if (!error) {
    return null;
  }
  return <p>{error}</p>;
};
