import React from "react";

function LotteryRulesModal({
  setShowLotteryRules,
}: {
  setShowLotteryRules: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="z-10 w-full min-h-screen p-4 flex items-center justify-center absolute left-0 right-0 top-0 ml-auto mr-auto bg-black ">
      <div className="flex justify-center animate-fadeIn items-center flex-col gap-10 lg:gap-[50px] bg-white w-full max-w-[500px] text-black px-[20px] lg:px-[20px] py-[25px] lg:py-[30px] rounded-3xl">
        <h1 className="text-center text-[24px] lg:text-[28px] leading-tight font-bold">
          Çekiliş Kuralları
        </h1>

        <p className="text-sm w-full pr-2 max-h-[400px] overflow-x-hidden overflow-y-scroll custom-scrollbar">
          Bu kampanya TURKCELL İLETİŞİM HİZMETLERİ A.Ş. adına MPİ’nin 13.09.2024
          tarih ve E-40453693-255.01.02-61206 sayılı izni ile Hedef Çekiliş
          tarafından düzenlenmektedir. Kampanya 16.09.2024 saat 00:01-15.12.2024
          saat 23:59 tarihleri arasında Türkiye genelinde Turkcell İletişim
          Hizmetleri AŞ’ye ait fiziksel mağazalara giderek (Turkcell
          mağazaları&nbsp;
          <a
            className="text-blue-500"
            target={"_blank"}
            rel="noreferrer"
            href="https://www.turkcell.com.tr/tr/hakkimizda/iletisim/turkcell-iletisim-merkezleri"
          >
            https://www.turkcell.com.tr/tr/hakkimizda/iletisim/turkcell-iletisim-merkezleri
          </a>
          &nbsp;adresinde yer almaktadır.) fiziksel mağaza içerisinde yer alan
          kampanya katılım kioskları üzerinden, katılım formunu ad-soyad, gsm
          no, adres bilgileri şeklinde doldurarak kayıt oluşturan bireysel
          Turkcell müşterilerine 1 çekiliş hakkı verilecektir. Kampanyaya
          katılım günlük 1 çekiliş hakkı ile sınırlı olup kampanya dönemi
          boyunca toplam 91 çekiliş hakkı ile sınırlıdır.Talihliler, 27.12.2024
          tarihinde Hedef Çekiliş ve Organizasyon Hizmetleri LTD. ŞTİ. (Toplantı
          Salonu) Esentepe Mah. Kore Şehitleri Cad. 16/1 İç Kapı no: 59 Şişli/
          İSTANBUL adresinde saat 11:00’da noter huzurunda halka açık olarak
          yapılacak çekilişle belirlenecektir. Kampanya genelinde yapılacak olan
          çekilişle, 3 kişiye 1.923.000,00 TL değerinde TOGG V2 LR RWD 22 kW
          Winter Pack PR Meridian 2024 Model, 5 kişiye 224.000,00 TL değerinde
          VESPA PRIMAVERA S 150 2023 Model Motosiklet, 3 kişiye 58.989,00 TL
          değerinde APPLE IPHONE 15 128GB, 30 kişiye 51.568,80 TL değerinde Dell
          G16 7630 Notebook , 30 kişiye 29.999,00 TL değerinde SONY PS5 SLIM 1TB
          DIGITAL KONSOL, 30 kişiye 15.999,00 TL değerinde SEGWAY-NINEBOOT E2
          SCOOTER, 200 kişiye 5.851,20 TL değerinde SAMSUNG GALAXY TAB A9+ 64GB
          ikramiyesi verilecektir. İkramiye kazanan talihliler, 31.12.2024
          tarihli Akşam Gazetesi’nde ilan edilecektir. Kampanya Turkcell
          bireysel müşterilerine yöneliktir. Bir kişi birden fazla ikramiye
          kazanamaz. Kampanya tarihleri arasında Turkcell aboneliklerinden
          ayrılan hat sahibi müşterilerin çekiliş hakları iptal edilecektir.
          Katılımlarda Ad-Soyad ve GSM no bilgilerinden herhangi birinin eksik
          veya hatalı olması halinde çekiliş hakkı verilmeyecektir. Verilen
          bilgilerinin güncelliği, doğruluğu, yeterliliği ve ispatı katılımcının
          sorumluluğundadır. Katılımcının ikramiye kazanması durumunda adres
          bilgileri, eksik veya bilinmiyor ise gazetede yapılan ilan tebliğ için
          yeterli olacaktır. Türkiye’ de ikamet eden yabancı uyruklu kişilerin
          tescile bağlı ikramiye kazanmaları halinde ikramiye tesliminde
          ikametgâh teskeresi ibrazı zorunludur. Turkcell İletişim Hizmetleri
          A.Ş. çalışanları, Turkcell Grup çalışanları ve Hedef Çekiliş ve
          Organizasyon Hizmetleri LTD. ŞTİ. çalışanları ile 18 yaşından küçükler
          düzenlenen piyango ve çekilişe katılamaz, katılmış ve kazanmış olsalar
          dahi ikramiyeleri verilemez. İkramiyeye konu olan eşya ve /veya
          hizmetin bedeli içinde bulunan KDV+ÖTV gibi vergiler dışındaki vergi
          ve diğer yasal yükümlülükler talihliler tarafından ödenecektir.
        </p>

        <button
          onClick={() => setShowLotteryRules(false)}
          className="text-[24px] lg:text-[32px] w-full lg:w-[300px] h-[50px] lg:h-[50px] border-2 border-[#FFC900] rounded-full"
        >
          Anladım
        </button>
      </div>
    </div>
  );
}

export default LotteryRulesModal;
