import { Formik, Field, Form, FormikErrors } from "formik";
import React, { useRef, useState } from "react";
import FormInput from "./FormInput";
import * as Yup from "yup";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import InputMask from "react-input-mask";
import { motion } from "framer-motion";
import { ModalType } from "../App";
import { getCodeFromUrl } from "../utils/getCodeFromUrl";
import Spinner from "../icons/spinner";
import config from "../utils/config";
import LotteryRulesModal from "./LotteryRulesModal";
import { Api } from "../utils/api";
import CustomFormSelect from "./elements/CustomFormSelect";
import ClarificationTextModal from "./ClarificationTextModal";
import { ExternalToast, toast } from "sonner";
interface ToggFormProps {
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
}

const TOAST_CONFIG: ExternalToast = {
  duration: 2500,
  position: "top-center",
};

type TurnstileStatus = true | false | null;

function ToggForm({ setModalType }: ToggFormProps) {
  const [captchaToken, setCaptchaToken] = useState("");
  const [formStep, setFormStep] = useState<number>(1);
  const [smsCode, setSmsCode] = useState<string>("");
  const [validityPeriod, setValidityPeriod] = useState<number>(
    Number(config.REACT_APP_SMS_CODE_VALIDITY_PERIOD)
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showLotteryRules, setShowLotteryRules] = useState<boolean>(false);
  const [showClarificationText, setShowClarificationText] =
    useState<boolean>(false);
  const [formSubmitLoading, setFormSubmitLoading] = useState<boolean>(false);
  const [statusSuccess, setStatusSuccess] = useState<TurnstileStatus>(null);
  const turnstileRef = useRef<TurnstileInstance>(null);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const urlCode = getCodeFromUrl();

  const goNextStep = () => {
    if (formStep < Number(config.REACT_APP_TOTAL_FORM_STEP)) {
      setFormStep((prev) => prev + 1);
      setErrorMessage("");
    } else {
      setFormStep(1);
    }
  };

  const startInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      setValidityPeriod((prev) => {
        if (prev <= 0) {
          clearInterval(intervalRef.current!);
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <Formik
      validationSchema={FormValidationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values) => {
        if (statusSuccess === false) {
          return toast.error(
            "Bir hata oluştu, lütfen tekrar deneyiniz.",
            TOAST_CONFIG
          );
        }
        setFormSubmitLoading(true);
        values.cfTurnstileResponse = captchaToken;

        let formData = {
          name: values.name,
          surname: values.surname,
          gsmNumber: values.gsmNumber,
          address: values.address,
          city: values.city,
          district: values.district,
        };

        const postData = {
          gsmNumber: values.gsmNumber,
          formData: formData,
          cfTurnstileResponse: captchaToken,
        };

        try {
          const formResponse = await Api.request({
            method: "POST",
            url: "/v1/raffle",
            params: { c: urlCode },
            payload: postData,
          });

          if (formResponse.status) {
            goNextStep();
            setFormSubmitLoading(false);
          } else {
            if (formResponse?.type === "linkExpired") {
              setErrorMessage("Bu linkin süresi geçmiştir.");
            } else if (formResponse?.type === "OnlyAttendOnceaDayError") {
              setModalType("onlyAttendOnceADay");
            } else if (formResponse?.type === "invalidHour") {
              setErrorMessage(
                "Sadece sabah 08:00 ve akşam 22:00 arasında kayıt oluşturulabilir."
              );
            } else {
              setErrorMessage("Form gönderilirken bir hata oluştu.");
            }
            setFormSubmitLoading(false);
          }
        } catch (error) {
          setErrorMessage("Form gönderilirken bir hata oluştu.");
        } finally {
          setFormSubmitLoading(false);
        }
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form
          className={`flex justify-center flex-col gap-4 lg:gap-[20px] ${
            formStep === 3 ? "h-auto" : "h-full"
          }`}
        >
          {showLotteryRules && (
            <LotteryRulesModal setShowLotteryRules={setShowLotteryRules} />
          )}
          {showClarificationText && (
            <ClarificationTextModal
              setShowClarificationText={setShowClarificationText}
            />
          )}
          {formStep === 1 && (
            <FormStep1
              urlCode={urlCode}
              errors={errors}
              formStep={formStep}
              goNextStep={goNextStep}
              loading={loading}
              setErrorMessage={setErrorMessage}
              setFieldValue={setFieldValue}
              setLoading={setLoading}
              setModalType={setModalType}
              startInterval={startInterval}
              values={values}
            />
          )}
          {formStep === 2 && (
            <FormStep2
              formStep={formStep}
              goNextStep={goNextStep}
              intervalRef={intervalRef}
              setErrorMessage={setErrorMessage}
              setFormStep={setFormStep}
              setSmsCode={setSmsCode}
              setValidityPeriod={setValidityPeriod}
              smsCode={smsCode}
              startInterval={startInterval}
              urlCode={urlCode}
              validityPeriod={validityPeriod}
              values={values}
            />
          )}
          {formStep === 3 && (
            <FormStep3
              turnstileRef={turnstileRef}
              setStatusSuccess={setStatusSuccess}
              formSubmitLoading={formSubmitLoading}
              setShowClarificationText={setShowClarificationText}
              setFieldValue={setFieldValue}
              errors={errors}
              formStep={formStep}
              setCaptchaToken={setCaptchaToken}
              setShowLotteryRules={setShowLotteryRules}
              touched={touched}
              values={values}
            />
          )}
          {formStep === 4 && <FormStep4 />}
          {errorMessage && (
            <p className="text-xl text-center">{errorMessage}</p>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ToggForm;

type FormStep1Props = {
  formStep: number;
  setFieldValue: any;
  values: formValues;
  errors: FormikErrors<formValues>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
  startInterval: () => void;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  goNextStep: () => void;
  urlCode: string | null;
};

const FormStep1 = ({
  formStep,
  setFieldValue,
  values,
  errors,
  loading,
  setLoading,
  setModalType,
  startInterval,
  setErrorMessage,
  goNextStep,
  urlCode,
}: FormStep1Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const adjustCursorPosition = (
    ref: React.RefObject<HTMLInputElement>,
    newValue: string
  ) => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.setSelectionRange(newValue.length + 1, newValue.length + 1);
      }
    }, 0);
  };

  const processGsmValue = (
    value: string,
    setFieldValue: (field: string, value: any) => void,
    inputRef: React.RefObject<HTMLInputElement>
  ) => {
    const digitsOnly = value.replace(/\D/g, "");
    if (digitsOnly && !digitsOnly.startsWith("0")) {
      const newValue = "0" + digitsOnly;
      setFieldValue("gsmNumber", newValue);
      adjustCursorPosition(inputRef, newValue);
    } else {
      setFieldValue("gsmNumber", digitsOnly);
    }
  };

  const handleOnClickGsmNumber = async (gsmNumber: string) => {
    setLoading(true);

    const res = await Api.request({
      method: "POST",
      url: "/send-sms",
      params: { c: urlCode },
      payload: { gsmNumber },
    });

    if (res.status) {
      setLoading(false);
      goNextStep();
      startInterval();
    } else {
      if (res.type === "anotherOperatorCantAttend") {
        setModalType("anotherOperatorCantAttend");
      } else if (res.type === "staffCantAttend") {
        setModalType("staffCantAttend");
      } else if (res.type === "OnlyAttendOnceADay") {
        setModalType("onlyAttendOnceADay");
      } else if (res.type === "invalidShopCode") {
        alert(
          "Çekilişe katılmak için lütfen Turkcell Mağazalarını ziyaret edin."
        );
      } else {
        setErrorMessage("Bir hata oluştu, lütfen tekrar deneyiniz.");
      }
      setLoading(false);
    }
  };

  return (
    <div className="flex h-full flex-col gap-4">
      <h1 className="text-[32px] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] text-center lg:text-[50px] font-bold leading-tight">
        Çekilişe katılman için bir kod göndereceğiz. <br /> Lütfen erişimin olan
        bir numara gir.
      </h1>
      <motion.div
        className="flex flex-col gap-2 justify-center h-full"
        key={formStep}
        initial={{ x: 30, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -30, opacity: 0 }}
        transition={{ type: "spring", stiffness: 200 }}
      >
        <Field name="gsmNumber">
          {({ field }: any) => (
            <InputMask
              type="text"
              placeholder="Telefon numaran"
              className="w-full h-[50px] lg:h-[50px] rounded-full pl-4 lg:pl-[20px] text-[24px] lg:text-[28px] text-black"
              {...field}
              mask="0 999 999 99 99"
              onChange={(e) => {
                processGsmValue(e.target.value, setFieldValue, inputRef);
              }}
            >
              {(inputProps: any) => (
                <input
                  inputMode="numeric"
                  {...inputProps}
                  type="text"
                  ref={inputRef}
                />
              )}
            </InputMask>
          )}
        </Field>
      </motion.div>

      <button
        type="button"
        disabled={
          Boolean(errors.gsmNumber) || values.gsmNumber.length < 11 || loading
        }
        className="w-full flex justify-center items-center bg-[#FFC900] text-[24px] lg:text-[28px] text-black rounded-full min-h-[50px] lg:h-[55px] disabled:opacity-60"
        onClick={() => handleOnClickGsmNumber(values.gsmNumber)}
      >
        {loading ? <Spinner /> : " Telefonunu Doğrula"}
      </button>
    </div>
  );
};

type FormStep2Props = {
  validityPeriod: number;
  formStep: number;
  smsCode: string;
  setSmsCode: React.Dispatch<React.SetStateAction<string>>;
  values: formValues;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setValidityPeriod: React.Dispatch<React.SetStateAction<number>>;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  startInterval: () => void;
  intervalRef: React.MutableRefObject<NodeJS.Timeout | null>;
  urlCode: string | null;
  goNextStep: () => void;
};

const FormStep2 = ({
  validityPeriod,
  values,
  formStep,
  smsCode,
  setSmsCode,
  setErrorMessage,
  setValidityPeriod,
  setFormStep,
  startInterval,
  intervalRef,
  urlCode,
  goNextStep,
}: FormStep2Props) => {
  const handleOnClickSmsCode = async (
    gsmNumber: string,
    smsCode: string | null
  ) => {
    const res = await Api.request({
      method: "POST",
      url: "/check-sms",
      params: { c: urlCode },
      payload: { smsCode, gsmNumber },
    });

    if (res.status) {
      goNextStep();
    } else if (res?.type === "invalidShopCode") {
      setErrorMessage(
        "Geçersiz bayi kodu, lütfen bayilerden qr kodu okutup tekrar deneyiniz."
      );
    } else if (res?.type === "invalidSmsCode") {
      setErrorMessage("Hatalı kod, tekrar deneyiniz.");
    } else {
      setErrorMessage("Beklenmedik bir hata oluştu.");
    }
  };

  const handleSmsCodeMaskInput = (value: string) => {
    const smsCodeNoSpace = value.split(" ").join("");
    setSmsCode(smsCodeNoSpace);
  };

  const goPrevStep = () => {
    if (formStep > 1) {
      setValidityPeriod(Number(config.REACT_APP_SMS_CODE_VALIDITY_PERIOD));
      setErrorMessage("");
      setSmsCode("");
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      setFormStep((prev) => prev - 1);
    }
  };

  const handleSendNewSmsCode = async (gsmNumber: string) => {
    const res = await Api.request({
      method: "POST",
      url: "/send-sms",
      params: { c: urlCode },
      payload: { gsmNumber },
    });

    if (res.status) {
      setValidityPeriod(Number(config.REACT_APP_SMS_CODE_VALIDITY_PERIOD));
      setErrorMessage("");
      setSmsCode("");
      startInterval();
    } else {
      if (res.type === "invalidShopCode") {
        setErrorMessage(
          "Çekilişe katılmak için lütfen Turkcell Mağazalarını ziyaret edin."
        );
      } else if (res.type === "expiredShopCode") {
        setErrorMessage("Qr kodun süresi geçmiştir. Lütfen tekrar okutunuz.");
      }
    }
  };

  return (
    <div className="flex h-full flex-col gap-4">
      <h1 className="text-[32px] text-center lg:text-[50px]  drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] font-bold leading-tight">
        Lütfen cep telefonuna gelen kodu eksiksiz bir şekilde gir:
      </h1>
      <p className="text-white text-center text-[24px] lg:text-[28px] leading-tight">
        {validityPeriod}
      </p>
      <motion.div
        className="flex flex-col gap-2 justify-center h-full"
        key={formStep}
        initial={{ x: 30, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -30, opacity: 0 }}
        transition={{ type: "spring", stiffness: 200 }}
      >
        <InputMask
          inputMode="numeric"
          autoComplete="one-time-code"
          alwaysShowMask
          type="text"
          className="w-full text-center h-[50px] lg:h-[50px] rounded-full pl-4 lg:pl-[20px] text-[24px] lg:text-[28px] text-black"
          mask="999 999"
          maskChar={null}
          value={smsCode}
          placeholder="000 000"
          onChange={(e) => handleSmsCodeMaskInput(e.target.value)}
        />
      </motion.div>
      {validityPeriod === 0 ? (
        <div className="flex flex-col gap-4 lg:flex-row w-full">
          <button
            onClick={goPrevStep}
            className="w-full bg-[#FFC900] text-[24px] lg:text-[28px] text-black rounded-full h-[50px] lg:h-[55px] disabled:opacity-60"
          >
            Geri Dön
          </button>
          <button
            onClick={() => handleSendNewSmsCode(values.gsmNumber)}
            className="w-full bg-[#FFC900] text-[24px] lg:text-[28px] text-black rounded-full h-[50px] lg:h-[55px] disabled:opacity-60"
          >
            Tekrar kod gönder
          </button>
        </div>
      ) : (
        <button
          disabled={smsCode === null || smsCode?.length < 6}
          onClick={() => handleOnClickSmsCode(values.gsmNumber, smsCode)}
          className="w-full bg-[#FFC900] text-[24px] lg:text-[28px] text-black rounded-full min-h-[50px] lg:h-[55px] disabled:opacity-60"
        >
          Doğrula
        </button>
      )}
    </div>
  );
};

type FormStep3Props = {
  errors: FormikErrors<formValues>;
  formStep: number;
  touched: any;
  values: formValues;
  setShowLotteryRules: React.Dispatch<React.SetStateAction<boolean>>;
  setShowClarificationText: React.Dispatch<React.SetStateAction<boolean>>;
  setCaptchaToken: React.Dispatch<React.SetStateAction<string>>;
  setFieldValue: any;
  formSubmitLoading: boolean;
  setStatusSuccess: React.Dispatch<React.SetStateAction<TurnstileStatus>>;
  turnstileRef: React.RefObject<TurnstileInstance>;
};

const FormStep3 = ({
  turnstileRef,
  setStatusSuccess,
  setCaptchaToken,
  setShowLotteryRules,
  setShowClarificationText,
  formSubmitLoading,
  errors,
  touched,
  values,
  formStep,
  setFieldValue,
}: FormStep3Props) => {
  return (
    <div className="flex h-full gap-4 flex-col">
      <h1 className="text-[32px] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] text-center lg:text-[50px] font-bold leading-tight">
        Lütfen formu eksiksiz doldurun.
      </h1>
      <motion.div
        className="flex flex-col gap-4 h-full justify-end "
        key={formStep}
        initial={{ x: 30, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -30, opacity: 0 }}
        transition={{ type: "spring", stiffness: 200 }}
      >
        <FormInput
          name="name"
          placeholder="Ad"
          type="text"
          isInvalid={errors.name && touched.name}
          errorMessage={errors.name}
        />
        <FormInput
          name="surname"
          placeholder="Soyad"
          type="text"
          isInvalid={errors.surname && touched.surname}
          errorMessage={errors.surname}
        />
        <CustomFormSelect
          cityValue={values.city}
          districtValue={values.district}
          isCityInvalid={errors.city && touched.city}
          isDistrictInvalid={errors.district && touched.district}
          errorMessageCity={errors.city}
          errorMessageDistrict={errors.district}
          setFieldValue={setFieldValue}
        />
        <FormInput
          name="address"
          placeholder="Açık Adres"
          type="text"
          isInvalid={errors.address && touched.address}
          errorMessage={errors.address}
        />
      </motion.div>

      <div className="flex items-center gap-5">
        <Field
          type="checkbox"
          name="consentCheckBox"
          className="size-[25px] lg:size-[30px]"
        />
        <p className="text-[16px] lg:text-[24px]">
          <u
            className="cursor-pointer"
            onClick={() => setShowLotteryRules(true)}
          >
            Çekiliş Kurallarını
          </u>{" "}
          okudum, anladım.
        </p>
      </div>
      <div className="flex items-center gap-5">
        <Field
          type="checkbox"
          name="clarificationCheckBox"
          className="size-[25px] lg:size-[30px]"
        />
        <p className="text-[16px] lg:text-[24px]">
          <u
            className="cursor-pointer"
            onClick={() => setShowClarificationText(true)}
          >
            Aydınlatma Metni'ni
          </u>{" "}
          okudum.
        </p>
      </div>
      <button
        disabled={
          formSubmitLoading ||
          !values.consentCheckBox ||
          !values.clarificationCheckBox ||
          Boolean(
            errors.address ||
              errors.name ||
              errors.surname ||
              errors.city ||
              errors.district
          )
        }
        type="submit"
        className="w-full bg-[#FFC900] text-[24px] lg:text-[28px] text-black rounded-full min-h-[50px] lg:h-[55px] disabled:opacity-60"
      >
        Çekilişe katıl
      </button>
      <Turnstile
        ref={turnstileRef}
        siteKey={config.REACT_APP_TURNSTILE_SITE_KEY}
        onError={() => setStatusSuccess(false)}
        onExpire={() => setStatusSuccess(false)}
        onSuccess={(token) => {
          setCaptchaToken(token);
          setStatusSuccess(true);
        }}
      />
    </div>
  );
};

const FormStep4 = () => {
  return (
    <div className="flex animate-fadeIn justify-center h-full flex-col gap-12">
      <h1 className="text-center drop-shadow-[0_4px_4px_rgba(0,0,0,0.8)] text-white text-[50px] font-bold lg:text-[50px] leading-[50px]">
        Tebrikler, <br /> 1 katılım hakkı kazandın!
      </h1>
      <p className="w-full text-center drop-shadow-[0_4px_4px_rgba(0,0,0,0.5)]">
        Unutmayın her gün 1 kez olmak üzere tekrar katılabilirsiniz. Çekiliş
        sonuçları Akşam Gazetesi’nde 31.12.2024 tarihinde açıklanacaktır. Bol
        şanslar!
      </p>
    </div>
  );
};

const initialValues: formValues = {
  name: "",
  surname: "",
  gsmNumber: "",
  address: "",
  city: "",
  district: "",
  consentCheckBox: false,
  clarificationCheckBox: false,
  cfTurnstileResponse: "",
};

export interface formValues {
  name: string;
  surname: string;
  gsmNumber: string;
  address: string;
  city: string;
  district: string;
  consentCheckBox: boolean;
  clarificationCheckBox: boolean;
  cfTurnstileResponse: string;
}

const FormValidationSchema = Yup.object({
  surname: Yup.string()
    .required("Soyad doldurulmalıdır *")
    .max(50, "Soyad en fazla 50 karakter olmalıdır *"),
  name: Yup.string()
    .required("Ad doldurulmalıdır *")
    .max(50, "Ad en fazla 50 karakter olmalıdır *"),
  city: Yup.string().required("İl doldurulmalıdır *"),
  district: Yup.string().required("İlçe doldurulmalıdır *"),
  gsmNumber: Yup.string()
    .max(11, "Telefon numarası en fazla 11 haneli olmalıdır *")
    .min(11, "Telefon numarası en az 11 haneli olmalıdır *")
    .required("Numara duldurulmalıdır *"),
  address: Yup.string()
    .required("Adres doldurulmalıdır *")
    .max(400, "Adres en fazla 400 karakter olmalıdır *"),
  consentCheckBox: Yup.boolean().required(
    "Lütfen çekiliş kurallarını okudum seçeneğini işaretleyin *"
  ),
  clarificationCheckBox: Yup.boolean().required(
    "Aydınlatma metnini okudum seçeneğini işaretleyin *"
  ),
});
