import React from "react";
import { ModalType } from "../App";

function FormModals({
  setModalType,
  modalType,
}: {
  modalType: ModalType;
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
}) {
  const renderModalContent = () => {
    switch (modalType) {
      case "staffCantAttend":
        return <StaffCantAttend setModalType={setModalType} />;
      case "anotherOperatorCantAttend":
        return <AnotherOperatorCantAttend setModalType={setModalType} />;
      case "onlyAttendOnceADay":
        return <OnlyAttendOnceADay setModalType={setModalType} />;
      default:
        return null;
    }
  };

  return renderModalContent();
}

const StaffCantAttend = ({
  setModalType,
}: {
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
}) => {
  return (
    <div className="z-10 w-full min-h-screen p-4 flex items-center justify-center absolute left-0 right-0 ml-auto mr-auto bg-black ">
      <div className="flex animate-fadeIn items-center justify-center flex-col gap-10 lg:gap-[50px] bg-white w-[500px] text-black px-[20px] lg:px-[20px] py-[25px] lg:py-[30px] rounded-3xl">
        <h1 className="text-center text-[24px] lg:text-[28px] leading-tight">
          <>
            <b> Maalesef Turkcell Elçileri bu çekilişe katılamıyor. </b>
            <br />
            Elçilere özel etkinlikler için lütfen takipte kal.
          </>
        </h1>
        <button
          onClick={() => setModalType("hide")}
          className="text-[24px] lg:text-[32px] w-full lg:w-[300px] h-[50px] lg:h-[50px] border-2 border-[#FFC900] rounded-full"
        >
          Anladım
        </button>
      </div>
    </div>
  );
};

const AnotherOperatorCantAttend = ({
  setModalType,
}: {
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
}) => {
  return (
    <div className="z-10 w-full min-h-screen p-4 flex items-center justify-center absolute left-0 right-0 ml-auto mr-auto bg-black ">
      <div className="flex animate-fadeIn items-center justify-center flex-col gap-10 lg:gap-[50px] bg-white w-[500px] text-black px-[20px] lg:px-[20px] py-[25px] lg:py-[30px] rounded-3xl">
        <h1 className="text-center text-[24px] lg:text-[28px] leading-tight">
          <>
            <b>
              Maalesef Turkcell’li olmayanlar çekilişe katılamıyor. <br />
              Çekilişe katılmak için hemen turkcelli ol
            </b>
          </>
        </h1>
        <button
          onClick={() => setModalType("hide")}
          className="text-[24px] lg:text-[32px] w-full lg:w-[300px] h-[50px] lg:h-[50px] border-2 border-[#FFC900] rounded-full"
        >
          Anladım
        </button>
      </div>
    </div>
  );
};

const OnlyAttendOnceADay = ({
  setModalType,
}: {
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
}) => {
  return (
    <div className="z-10 w-full min-h-screen p-4 flex items-center justify-center absolute left-0 right-0 ml-auto mr-auto bg-black ">
      <div className="flex animate-fadeIn items-center justify-center flex-col gap-10 lg:gap-[50px] bg-white w-[500px] text-black px-[20px] lg:px-[20px] py-[25px] lg:py-[30px] rounded-3xl">
        <h1 className="text-center text-[24px] lg:text-[28px] leading-tight">
          <>
            <b>
              Malesef çekilişe günde bir kez katılabilirsin. <br />
              Şansını artırmak için yarın yine bekleriz!
            </b>
          </>
        </h1>
        <button
          onClick={() => setModalType("hide")}
          className="text-[24px] lg:text-[32px] w-full lg:w-[300px] h-[50px] lg:h-[50px] border-2 border-[#FFC900] rounded-full"
        >
          Anladım
        </button>
      </div>
    </div>
  );
};

export default FormModals;
