const values = {
  REACT_APP_TURNSTILE_SITE_KEY:
    process.env.REACT_APP_TURNSTILE_SITE_KEY || "0x4AAAAAAAgWa5GUReqQQlJl",
  REACT_APP_API_URL:
    process.env.REACT_APP_API_URL ||
    "https://trkcll-mdd22-back-01.turkcell-magaza.com/api",
  REACT_APP_TOTAL_FORM_STEP: process.env.REACT_APP_TOTAL_FORM_STEP || 4,
  REACT_APP_SMS_CODE_VALIDITY_PERIOD:
    process.env.REACT_APP_SMS_CODE_VALIDITY_PERIOD || 60,
};

export default values;
