import React, { useState } from "react";
import FormModals from "./components/FormModals";
import ToggForm from "./components/ToggForm";
import { Toaster } from "sonner";

export type ModalType =
  | "hide"
  | "anotherOperatorCantAttend"
  | "staffCantAttend"
  | "onlyAttendOnceADay";

function App() {
  const [modalType, setModalType] = useState<ModalType>("hide");
  return (
    <div className="w-full flex lg:py-4 justify-center h-screen items-center text-white relative">
      <Toaster />
      {modalType !== "hide" ? (
        <FormModals modalType={modalType} setModalType={setModalType} />
      ) : (
        <div className="w-full bg-form-background py-12 bg-center bg-no-repeat bg-cover overflow-y-scroll h-full lg:max-w-[768px] p-4 lg:p-12 flex flex-col gap-[20px]">
          <div className="flex justify-center min-h-[60px] lg:min-h-[100px]">
            <img
              className="h-auto lg:h-full object-contain"
              src="/images/turkcellLogo.png"
              alt="turkcellLogo"
            />
          </div>

          <ToggForm setModalType={setModalType} />
        </div>
      )}
    </div>
  );
}

export default App;
