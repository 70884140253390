import React from "react";

function ClarificationTextModal({
  setShowClarificationText,
}: {
  setShowClarificationText: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="z-10 w-full min-h-screen p-4 flex items-center justify-center absolute left-0 right-0 top-0 ml-auto mr-auto bg-black ">
      <div className="flex justify-center animate-fadeIn items-center flex-col gap-10 lg:gap-[50px] bg-white w-full max-w-[500px] text-black px-[20px] lg:px-[20px] py-[25px] lg:py-[30px] rounded-3xl">
        <h1 className="text-center text-[24px] lg:text-[28px] leading-tight font-bold">
          Aydınlatma Metni
        </h1>

        <p className="text-sm w-full max-h-[400px] overflow-y-scroll break-words custom-scrollbar">
          30. Yıl Turkcell Çekilişine katılmak için bu formu doldurarak
          paylaşacağınız kişisel verileriniz (ad-soyadı, GSM numarası, il-ilçe
          ve açık adres bilgileri) çekilişe katılmanız, çekilişle ilgili tüm
          bilgilendirmelerin tarafınıza yapılması ve bu amaçla tarafınızla
          iletişime geçilmesi, çekilişi kazanmanız halinde seçilen bir ulusal
          gazetede kazananların ilan edilmesi ve ödüllerin tarafınıza iletilmesi
          amaçlarıyla 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (Kanun)
          5’inci maddesi uyarınca bir sözleşmenin kurulması ve ifası için
          zorunlu olması ve bir hakkın tesisi için zorunlu olması hukuki
          sebeplerine dayanarak Turkcell İletişim Hizmetleri A.Ş (Turkcell)
          tarafından otomatik yöntemlerle işlenecek, Turkcell’in birlikte
          çalıştığı iş ortakları (ajanslar) ve Milli Piyango İdaresi ile
          paylaşılacaktır. Kişisel Verilerinize ilişkin detaylı bilgi almak ve
          Kanun’un 11’inci maddesindeki haklarınızı öğrenmek için&nbsp;
          <a
            className="text-blue-500"
            target={"_blank"}
            rel="noreferrer"
            href="https://www.turkcell.com.tr/tr/hakkimizda/gizlilik-ve-guvenlik/turkcell-aydinlatma-metni"
          >
            "Turkcell Aydınlatma Metni"
          </a>
          &nbsp;ni inceleyebilirsiniz.
        </p>

        <button
          onClick={() => setShowClarificationText(false)}
          className="text-[24px] lg:text-[32px] w-full lg:w-[300px] h-[50px] lg:h-[50px] border-2 border-[#FFC900] rounded-full"
        >
          Anladım
        </button>
      </div>
    </div>
  );
}

export default ClarificationTextModal;
