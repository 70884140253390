import axios, { Method } from "axios";
import config from "./config";

const BaseUrl = config.REACT_APP_API_URL;

type RequestConfig = {
  method: Method;
  url: string;
  params?: Record<string, any>;
  payload?: Record<string, any>;
};

export const Api = {
  request: async ({ method, url, params, payload }: RequestConfig) => {
    try {
      const query = params ? `?${new URLSearchParams(params).toString()}` : "";

      const response = await axios({
        method,
        url: `${BaseUrl}${url}${query}`,
        data: method !== "GET" ? payload : undefined,
      });

      return response.data;
    } catch (error: any) {
      return (
        error.response?.data ?? { error: `Unknown Error Occurred(${url})` }
      );
    }
  },
};
